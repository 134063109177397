import React from 'react';
import { InfiniteHorizontalScroller, Section } from '../../components';
import { Link } from 'react-router-dom';
import { BasicButton } from '../../components/button';
import { Architecture, Brush, FacebookRounded, FileDownloadOutlined, GitHub, LinkedIn, Polyline, School, Send, X } from '@mui/icons-material';
import { TestimonialSwiper } from '../../components/swiper';
import { LetsTalk } from '../../components/sections';
const images = [
    "https://i.ibb.co/chc6TnV/mdn-logo.png"
  ].map((image) => ({
    id: image+1,
    image
  }));

const experience = [
    {
        from: 2021,
        to: "Present",
        title: 'Web designer',
        desc: 'Themeforest Market',
        id: 1,
        logo: <Brush />
    },
    {
        from: 2021,
        to: 2023,
        title: 'Marketing Expert GRP',
        desc: 'Themeforest Market',
        id: 2,
        logo: <Architecture />
    },
    {
        from: 2021,
        to: 2022,
        title: 'Web designer',
        desc: 'Web Developer & Bussiness Partner',
        id: 3,
        logo: <Polyline />
    },
]

const education = [
    {
        from: 2013,
        to: 2015,
        title: 'Bachelor Degree of Information Technology',
        desc: 'State University bangladesh',
        id: 4,
        logo: <School />
    },
    {
        from: 2021,
        to: 2024,
        title: 'Higher secoundery Education',
        desc: 'Premium College United VC',
        id: 5,
        logo: <School />
    },
    {
        from: 2020,
        to: 2021,
        title: 'UI/UX Design',
        desc: 'Webster College',
        id: 6,
        logo: <School />
    },
]

const WorkCard = ({ doc }) => {
    return (
        <div className='work-card'>
            <div>
                <div className="card-logo">
                    {doc.logo}
                </div>
            </div>
            <div className='card-body'>
                <span>{doc.from} - {doc.to}</span>
                <h3>{doc.title}</h3>
                <p>{doc.desc}</p>
            </div>
        </div>
    )
}

export default function About() {
    return (
        <>
            <Section id="about" className={'about-area'}>
                <div className="container">
                    <div className="row">
                        <div className='about-image'>
                            <div className='home-section color-base'>
                                <img style={{
                                    width: 300
                                }} src="https://bentos-react.vercel.app/assets/profile-CzN_0cZS.png" />
                            </div>
                        </div>
                        <div className='about-section about-page'>
                            <div className="home-section color-base">
                                <h2>I’m Bentos Walker, a product designer.</h2>
                                <div className="flex flow-column gap-10 margin-bottom-10 margin-top-10">
                                    <p>I am a San francisco-based product designer with a focus on web design, illustration, a visual development. I have a diverse range of experience having worked across various fields and industries.</p>
                                    <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source.</p>
                                </div>
                                <BasicButton 
                                    title='Get in touch'
                                    className='margin-top-10'
                                    rightIcon={<Send />}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Section>
            <Section id="works" className="work-area">
                <div className="container">
                    <div className="row">
                        <div className='experience-section'>
                            <div className="home-section color-base">
                                <h2>Experience</h2>
                                <div className="work-list">
                                    {experience.map((doc) => (
                                        <WorkCard doc={doc} key={doc.id} />
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className='education-section'>
                            <div className="home-section color-base">
                                <h2>Education</h2>
                                <div className="work-list">
                                    {education.map((doc) => (
                                        <WorkCard doc={doc} key={doc.id} />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Section>
            <Section id="testimonials" className="testimonial-area">
                <div className="container">
                    <div className="home-section color-base">
                        <div className="section-title align-center">
                            <p>Testinomials</p>
                            <h2>What clients say!</h2>
                        </div>
                        <div className="testimonials-swiper">
                            <TestimonialSwiper />
                        </div>
                    </div>
                </div>
            </Section>
            <LetsTalk />
        </>
    )
}