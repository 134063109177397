import React, { useEffect, useState } from 'react';
import './Header.scss';
import { Link, NavLink } from 'react-router-dom';
import { BasicButton } from '../button';
import { HandshakeOutlined } from '@mui/icons-material';

const NavHeaderLink = ({ to, title }) => {
    const [ isActive, setIsActive ] = useState(false);
    const [ classNamer, setClassNamer ] = useState('');
    
    useEffect(() => {
        if (isActive) {
            setClassNamer('active');
            setTimeout(() => {
                setClassNamer('active removeLineAnimation');
                setTimeout(() => {
                    setClassNamer('active removeLine');
                    setTimeout(() => {
                        setClassNamer('active removeLineTransition')
                    }, 100);
                }, 100)
            }, 500)
        } else {
            setClassNamer('');
        }
    }, [isActive])

    return (
        <NavLink
            to={to}
            className={({ isActive }) => {
                setIsActive(isActive);
                return `nav-link-click ${classNamer}`;
              }}
        >
            {title}
        </NavLink>
    )
}

export default function Header() {
    const [ scrolled, setScrolled ] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            console.log('xd');
            if (window.scrollY > 16) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    
    console.log(scrolled)

    return (
        <header className='main-header'>
            <div className={`header-upper ${scrolled ? 'scrolled' : ''}`}>
                <div className="container">
                    <div className="header-inner flex align-center">
                        <div className="logo-outer">
                            <div className="logo">
                                <Link to="/">
                                    NoName
                                </Link>
                            </div>
                        </div>
                        <div className="nav-outer">
                            <nav className="main-menu">
                                <div className="navbar-header">

                                </div>
                                <div className="navbar-collapse">
                                    <ul className='navigation'>
                                        <li>
                                            <NavHeaderLink to="/" title="Inicio" />
                                        </li>
                                        <li>
                                            <NavHeaderLink to="/about" title="About" />
                                        </li>
                                        <li>
                                            <NavHeaderLink to="/service" title="Service" />
                                        </li>
                                        <li>
                                            <NavHeaderLink to="/works" title="Works" />
                                        </li>
                                        <li>
                                            <NavHeaderLink to="/contact" title="Contact" />
                                        </li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                        <div className="menu-btns">
                            <BasicButton 
                                variant='outlined'
                                title='Hire Me'
                                rightIcon={<HandshakeOutlined />}
                            />
                            <Link className='theme-btn'></Link>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}
