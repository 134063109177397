import React from 'react'

export default function InfiniteHorizontalScroller({ images, speed = 5000, arrayLength = 3}) {
    return (
        <div className='company-scroll inner'>
            <div className="wrapper">
                {Array.from({ length: arrayLength }).map((i) => (
                    <section key={i} style={{ "--speed": `${speed}ms`}}>
                        {images.map(({ id, image }) => (
                            <div className="image" key={id}>
                                <img src={image} alt={id} />
                            </div>
                        ))}
                    </section>
                ))}
            </div>
        </div>
    )
}
