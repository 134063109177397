import React, { useEffect } from 'react'
import { Header } from '../components/header'
import { Outlet, useLocation } from 'react-router-dom'

export default function Layout() {
    const location = useLocation();

    useEffect(() => {    
        window.scrollTo(0,0);
    }, [location.pathname])

    return (
        <>
            <Header />
            <Outlet />
        </>
    )
}
