import { createBrowserRouter } from "react-router-dom";
import { Layout } from ".";
import { Home } from "./home";
import { About } from "./about";
import { Service } from "./service";

export const PageRouter = createBrowserRouter([
    {
        path: "/",
        element: <Layout />,
        children: [
            {
                index: true,
                element: <Home />
            },
            {
                path: "about",
                element: <About />
            },
            {
                path: "service",
                element: <Service />
            }
        ]
    }
])